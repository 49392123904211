import styled from "@emotion/styled";
import { Link } from "@reach/router";
import React from "react";
import { Body } from "../../components/Body";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

const PriceListPage = () => (
  <>
    <Header />
    <Body>
      <h2>Price List</h2>
      <Subtitle>Finger food</Subtitle>
      <p>
        The cost of cocktail items ranges from $3.50 to $4.50 + gst. Food
        selection, volume and availability will be a factor in the final quote.
        Package pricing is also available.
        <br />4 items per hour of service is recommended.
      </p>
      <Subtitle>Lunch and Dinner (Pricing Per Person)</Subtitle>
      <p>
        Two courses: $60 + gst
        <br />
        Three courses: $70 + gst
        <br />
        Chef time may be added on small event: $220 + gst (call us to have a
        quote)
        <br />
        Parking costs will be added.
        <br />
        All prices are subject to change.
        <br />
      </p>
      <Subtitle>Sandwiches</Subtitle>
      <p>
        Ribbon/finger sandwich: $2.50 per finger (3 per serve recommended)
        <br />
        Gourmet mini roll selection: $4.50 per roll (2.5 per serve recommended)
        <br />
        Minimum order quantities and delivery fees may apply.
        <br />
      </p>
      <Subtitle>Buffets</Subtitle>
      <p>Please call us for a quote.</p>
      <Subtitle>Extras</Subtitle>
      <p>
        We can organise professional waiters and bar staff for your event.
        <br />
        An average price of $50 + gst per hour. With a minimum of 4 hours.
      </p>
      <br />
      <p>
        For all enquires please <Link to="/contact">send an enquiry</Link> or
        call PM Catering at 041 052 7922
      </p>
    </Body>
    <Footer />
  </>
);

export default PriceListPage;

const Subtitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;
